<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4 grey lighten-4">
      <div class="d-flex align-center justify-space-between mb-4">
        <p class="subtitle-1 ma-0">
          Selecione abaixo um produto para editar ou excluir.
        </p>
        <v-btn
          small
          depressed
          color="fmq_gray"
          dark
          to="/protudo-criar"
          v-if="produtoCriar"
          >Novo Produto</v-btn
        >
      </div>
      <ProdoutosTabela
        :headers="headers"
        :produtos="produtos"
        :loading="loading"
        :total="totalProdutos"
        :pageText="pageText"
        :pageNumber="page"
        @handleFilter="filter"
        @excluir="excluirModal"
      >
        <template v-slot:nome>
          <v-text-field
            v-model="formData.nome"
            placeholder="Digite o nome"
            solo
            flat
            dense
            :hide-details="true"
            @input="page = 1;getProdutos(formData)"
            :clearable="true"
          >
          </v-text-field>
        </template>

        <template v-slot:[`linha.nome`]>
          <v-autocomplete
            v-model="formData.linhasProdutosId"
            :items="listLinhas"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Digite a Linha"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="page = 1;getProdutos(formData)"
          ></v-autocomplete>
        </template>

        <template v-slot:[`aprovador.nome`]>
          <v-autocomplete
            v-model="formData.aprovadorIc"
            :items="listMedicoAprovadores"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Digite a Linha"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="page = 1;getProdutos(formData)"
          ></v-autocomplete>
        </template>

        <template v-slot:[`responsavel.nome`]>
          <v-autocomplete
            v-model="formData.responsavelTreinamento"
            :items="listResponsavel"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Digite o GEP"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="page = 1;getProdutos(formData)"
          ></v-autocomplete>
        </template>

        <template v-slot:[`empresaParceira.nome`]>
          <v-autocomplete
            v-model="formData.empresaParceira"
            :items="listEmpresasParceiras"
            hide-no-data
            hide-selected
            item-text="nome"
            item-value="id"
            placeholder="Digite a Empresa"
            solo
            flat
            dense
            :hide-details="true"
            :clearable="true"
            @input="page = 1;getProdutos(formData)"
          ></v-autocomplete>
        </template>

        <template v-slot:id>
          <div class="d-flex justify-start">
            <v-btn class="mr-2" outlined x-small fab color="red" @click="clear">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-btn outlined x-small fab color="blue" @click="search">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </div>
        </template>
      </ProdoutosTabela>
    </div>
    <AlertConfirmation
      :dialog="dialog"
      :dialogMessage="dialogMessage"
      @close="dialog = false"
      @accept="excluir"
    />
    <AlertSuccess
      :dialog="success"
      dialogMessage="Produto excluida com sucesso"
      @close="
        getProdutos(formData, page);
        success = false;
      "
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import ProdoutosTabela from "@/components/produtos/ProdoutosTabela.vue";
import { buscarProduto, excluirProduto } from "@/services/produtos";
import { listarLinha } from "@/services/linhas";
import { listarMedicoAprovadores, listFuncionarios } from "@/services/user";
import { listarEmpresaParceira } from "@/services/empresas-parceiras";
export default {
  name: "Produtos",
  components: { ProdoutosTabela },
  data: () => ({
    breadcrumbs: [
      {
        text: "Produtos",
        disabled: true,
        to: "Produtos",
      },
    ],
    headers: [
      { text: "Nome do Produto", value: "nome" },
      { text: "Linha do Produto", value: "linha.nome" },
      { text: "Assessor Médico", value: "aprovador.nome" },
      { text: "GPE", value: "responsavel.nome" },
      { text: "Empresa Parceira", value: "empresaParceira.nome" },
      { text: "Ações", value: "id", sortable: false },
    ],
    produtos: [],
    totalProdutos: null,
    pageText: null,
    loading: false,
    formData: {
      orderBy: null,
      orderSorted: null,
      perPage: 15,
      nome: null,
      linhasProdutosId: null,
      aprovadorIc: null,
      responsavelTreinamento: null,
      empresaParceira: null,
    },
    page: 1,
    dialog: false,
    dialogMessage: null,
    produtoId: null,
    error: false,
    success: false,
    messageError: null,
    listLinhas: [],
    listMedicoAprovadores: [],
    listEmpresasParceiras: [],
    listResponsavel: [],
  }),
    beforeCreate() {
    if(this.$store.state.user.data.tipo === "Representante") {
      this.$router.push({path: '/'})
    }
  },
  created() {
    if (this.$store.state.produtosSearch.length) {
      this.formData = { ...this.$store.state.produtosSearch[0] };
    }
    this.getProdutos(this.formData, this.page);
    this.getLinhas();
    this.getMedicoAprovadores();
    this.getEmpresasParceiras();
    this.getResponsavel();
  },
  methods: {
    async getProdutos(payload, page) {
      this.produtos = [];
      this.$store.dispatch("setProdutosSearch", payload);
      try {
        this.loading = true;
        const resp = await buscarProduto(payload, page);
        this.produtos = resp.data.data;
        this.totalProdutos = resp.data.total;
        this.pageText = `${resp.data.from}-${resp.data.to} de ${resp.data.total}`;
      } catch (e) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.$store.dispatch("setProdutosSearch", {});
      this.formData.nome = null;
      this.formData.linhasProdutosId = null;
      this.formData.aprovadorIc = null;
      this.formData.responsavelTreinamento = null;
      this.formData.empresaParceira = null;
      this.page = 1;
      this.getProdutos(this.formData, this.page);
    },
    search() {
      this.page = 1;
      this.getProdutos(this.formData, this.page);
    },
    filter(event) {
      this.$store.dispatch("setProdutosSearch", this.formData);
      this.formData.orderSorted = event.orderSorted;
      this.formData.perPage = event.perPage;
      this.page = event.page;
      this.getProdutos(this.formData, this.page);
    },
    async excluir() {
      this.error = false;
      this.sucess = false;
      try {
        await excluirProduto(this.produtoId).then(() => {
          this.dialog = false;
          this.success = true;
        });
      } catch (e) {
        this.dialog = false;
        this.error = true;
        this.messageError = e.response.data.message;
      }
    },
    excluirModal(item) {
      this.dialogMessage = `Tem certeza que deseja excluir ${item.nome}?`;
      this.dialog = true;
      this.produtoId = item.id;
    },
    async getLinhas() {
      const resp = await listarLinha();
      this.listLinhas = resp.data;
    },
    async getMedicoAprovadores() {
      const resp = await listarMedicoAprovadores();
      this.listMedicoAprovadores = resp.data;
    },
    async getResponsavel() {
      const resp = await listFuncionarios({ mesaId: 2 });
      this.listResponsavel = resp.data;
    },
    async getEmpresasParceiras() {
      const resp = await listarEmpresaParceira();
      this.listEmpresasParceiras = resp.data;
    },
  },
  computed: {
    produtoCriar() {
      return this.$store.state.user.data.permissoes.includes(
        "produto.criar"
      );
    },
  },
};
</script>

<style></style>
